import React, { Component } from "react";
import { CircularProgress, Dialog, DialogContent, DialogActions, Button, Box, IconButton } from "@material-ui/core";
import { Close } from '@material-ui/icons';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import Input from "@mui/material/Input";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import axios from "axios";
import { toXML } from 'jstoxml';
import BootstrapInput from "../../components/BootstrapInput";
import config from '../../config/config';
const NODEURL = config.getConfig().lcmService;
const CLIENTIDURL = config.getConfig().clientIdService;
let newValue;
export default class Test extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      SpinnerFlag: false,
      fullfillmentCheck: false,
      sourceProgress: false,
      dataSourceProgress: false,
      managementProgress: false,
      methodUrlProgress: false,
      payloadToSubmitProgress: false,
      filterdSources: [],
      filterdDataSources: [],
      filteredManagement: [],
      ConfigData: {},
      LeadConfig: {
        source: "",
        datasource: "",
        management: "",
        apiurl: "",
        apimethod: "",
        headers: {},
        payloadschematype: "JSON",
        payload: { KEY: "VALUE" },
        authentication: false,
        payloadMapping: [],
      },
      LCSPayload: { KEY: "VALUE" },
      PayloadSubmit: {KEY: "VALUE"},
      open: false,
    };
    newValue = this.state.LeadConfig;
    this.getPayloadToSubmit = this.getPayloadToSubmit.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.setCommon = this.setCommon.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.getleadConfigSourceList();
  }
  clearFullfillment = (e) => { 
    newValue.FullfillmentId = "";
    this.setState({
      fullfillmentCheck: false,
      LeadConfig: newValue
     });
  }
  async getfullfillmetDetails(){
    this.setState({sourceProgress: true});
    let APIurl = `${NODEURL}/getConfiguration?FullfillmentId=` + this.state.LeadConfig.FullfillmentId;
    fetch(`${APIurl}`).then((res) => {
      if (res.ok) {
        return res.json();
      }
      }).then((response) => {
        if(response.Count === 0){
          this.setState({fullfillmentCheck : true, sourceProgress: false});
        } else{
          console.log("filterdfullfillmentData",response);
          newValue.source = response.Items[0].source ;
          newValue.datasource = response.Items[0].datasource ;
          newValue.management = response.Items[0].management ;
          this.setState({fullfillmentCheck : false, ConfigData:  response.Items[0], LeadConfig: newValue , sourceProgress: false, tableCheck: true});
        }
      }).catch((error) => {
        newValue.source= "";
        newValue.datasource= "";
        newValue.management= "";
        this.setState({fullfillmentCheck: false, ConfigData: [], LeadConfig: newValue, sourceProgress: false, tableCheck: false});
      });
  }
  async getleadConfigSourceList() {
    this.setState({ sourceProgress: true});
    let APIurl = `${NODEURL}/getAllConfiguration`;
    fetch(`${APIurl}`).then((res) => {
      if (res.ok) { return res.json(); } }).then((response) => {
        console.log("filterdSources", response)

        const unique = [...new Set(response.LeadConfiguration.map((item) => item.source))];
        this.setState({ filterdSources: unique, sourceProgress: false });

      }).catch((error) => { 

        this.setState({filterdSources : [], sourceProgress: false});

        console.log(error)});
  }
  async getleadConfigDataSourceList() {
    this.setState({dataSourceProgress: true});
    let APIurl = `${NODEURL}/getConfiguration?source=` + this.state.LeadConfig.source;

    fetch(`${APIurl}`).then((res) => {
      if (res.ok) { return res.json();} }).then((response) => {
        console.log("filterdDataSources",response)

        const unique = [...new Set(response.Items.map((item) => item.datasource))];
        this.setState({ filterdDataSources: unique, dataSourceProgress: false });

      }).catch((error) => {
        
        this.setState({filterdDataSources: [], dataSourceProgress: false});

        console.log(error)
      });
  }
  async getleadConfigManagementList() {
    this.setState({ managementProgress: true }); 
    let APIurl = `${NODEURL}/getConfiguration?source=` + this.state.LeadConfig.source +`&datasource=` + this.state.LeadConfig.datasource;
    fetch(`${APIurl}`).then((res) => {
      if (res.ok) {
        return res.json();
      }
      }).then((response) => {
        console.log("filteredManagement",response);

        const unique = response.Items.filter(item => {return item.management !== "" && item.management !== undefined } );
        const withoutManagement = response.Items.filter(item => {return item.management === ""} );
        console.log("config available with withmanagement: ", unique);
        console.log("config available without management: ", withoutManagement[0]);
        this.setState({
          filteredManagement: unique,
          ConfigData: unique.length !== 0 ? {} : withoutManagement[0],
          managementProgress: false,
          tableCheck: withoutManagement.length === 1 ? true : false
        })

      }).catch((error) => {
        this.setState({ConfigData: [], managementProgress: false});
        console.log(error);
      });
  }
  getleadConfigData() {
    this.setState({ SpinnerFlag: true });
    let APIurl = `${NODEURL}/getConfiguration?source=` + this.state.LeadConfig.source +`&datasource=` +this.state.LeadConfig.datasource +`&management=` +  this.state.LeadConfig.management;
    fetch(`${APIurl}`).then((res) => {
      if (res.ok) { return res.json(); } }).then((response) => {
        console.log("filterdDataDetails",response)

        this.setState({
          ConfigData: response.Items.length > 0 ? response.Items[0] : {},
          SpinnerFlag: false,
          tableCheck: response.Items.length === 1 ? true : false
        });

      }).catch((error) => {

        this.setState({ConfigData: [], SpinnerFlag: false});
        console.log(error);
      });
  }
  setFullfillmentId = (e) => {
    newValue.FullfillmentId = e.target.value;
    this.setState({ LeadConfig: newValue });
    if(this.isNumber(e.target.value) && e.target.value.length > 0){
      this.getfullfillmetDetails(e.target.value);
    }
    if(e.target.value.length === 0){
      newValue.source = "";
      newValue.datasource = "";
      newValue.management = "";
      this.setState({fullfillmentCheck: false, ConfigData: [], LeadConfig: newValue, sourceProgress: false, tableCheck: false});
    }
  };
  isNumber(str) {
    if (str.trim() === '') {
      return false;
    }
    return !isNaN(str);
  }
  setLeadsPlatformPayload = (e) => {
    const obj = e.jsObject;
    const flatten = (obj, roots=[], sep='.') => Object.keys(obj).reduce((memo, prop) => Object.assign({}, memo, Object.prototype.toString.call(obj[prop]) === '[object Object]' ? flatten(obj[prop], roots.concat([prop]), sep) : {[roots.concat([prop]).join(sep)]: obj[prop]}), {});
    let flattenObj = flatten(obj);
    console.log(flattenObj);
    this.setState({ LCSPayload: e.jsObject });
  };
  setCommon = (event) => {
    const { value, name } = event.target;
    if (name === "source") {
      newValue[name] = value;
      this.getleadConfigDataSourceList();
    }
    if (name === "datasource") {
      newValue[name] = value;
      this.getleadConfigManagementList();
    }
    if (name === "management") {
      newValue[name] = value;
      this.getleadConfigData();
    }
    this.setState({ LeadConfig: newValue });
  };
  // setServiceType = (e) => {
  //   this.setState({servicetype : e.target.value});
  // }
  clearSet = (e) => { 
    this.setState({
      PayloadSubmit: {KEY: "VALUE"},
      open: false
    })
  }
  clearFilters = (e) => {
    let ResetVal = this.state.LeadConfig;
    let ResetConfig = this.state.ConfigData;
    ResetVal.source = "";
    ResetVal.datasource = "";
    ResetVal.management = "";
    ResetConfig.apiurl = "";
    ResetConfig.apimethod = "";
    ResetVal.FullfillmentId = "";
    ResetVal.headers = "";
    this.setState({LeadConfig: ResetVal , ConfigData : ResetConfig});
  }
  setValueDecrypt(obj){
    var decryptedPassword = "";
    if(obj.length !== 0){
      var encryptedString = obj;
      var key = encryptedString[encryptedString.length -1 ];
      var decryptedString = [];
      for(var i = 0 ; i < encryptedString.length - 1 ; i++){
        decryptedString[i] = encryptedString[i].charCodeAt(0) - key.charCodeAt(0);
      }
      for(i = 0 ; i < decryptedString.length ; i++){
        decryptedPassword= decryptedPassword + String.fromCharCode(decryptedString[i]);
      }
      return decryptedPassword;
    }
  }
  async getPayloadToSubmit() {
    this.setState({payloadToSubmitProgress : true});
    let payloadToSubmit = {};
    var newVal = this.state.ConfigData;
    if (this.state.ConfigData.queryParams !== undefined && this.state.ConfigData.queryParams[0].queryParamKey !== "" && this.state.ConfigData.queryParams[0].queryParamValue !== "") {
     for(let x = 0 ; x < this.state.ConfigData.queryParams.length ; x++){
      if(this.state.ConfigData.queryParams[x].queryParamKey !== "" && this.state.ConfigData.queryParams[x].queryParamValue !== ""){
        newVal.apiurl = newVal.apiurl+"?"+this.state.ConfigData.queryParams[x].queryParamKey+"="+this.state.ConfigData.queryParams[x].queryParamValue
      }
     }
      const [key, ...rest] = newVal.apiurl.split('?')
      const value = rest.join('&')
      newVal.apiurl = key+"?"+value;
      this.setState({ConfigData : newVal});
    }
    if (this.state.ConfigData.headerDetails.some(e => e.valueType === 'IpAddress')) {
      var ipdata = this.state.ConfigData.headerDetails.find(function(ele) { return ele.valueType === 'IpAddress';});
    
      newVal.headers[ipdata.headerKey]= this.state.LCSPayload.ip;
      this.setState({ConfigData : newVal});
    }
    if (this.state.ConfigData.headerDetails.some(e => e.valueType === 'Basic')) {
      var bdata = this.state.ConfigData.headerDetails.find(function(ele) {return ele.valueType === 'Basic';});
    
      let passwd = this.setValueDecrypt(this.state.ConfigData.password);
      newVal.headers[bdata.headerKey] = ["Basic "+ btoa(this.state.ConfigData.username +":"+ passwd)]
      this.setState({ConfigData : newVal});
    }
    if (this.state.ConfigData.headerDetails.some(e => e.valueType === 'OAuth')) {
      var odata = this.state.ConfigData.headerDetails.find(function(ele) { return ele.valueType === 'OAuth';});
    
      let getfinalResponse = await this.callRequestUrl(this.state.ConfigData);
      newVal.headers[odata.headerKey] = ["Bearer "+getfinalResponse.access_token];
      this.setState({ConfigData : newVal});
    }

    if (this.state.ConfigData.headerDetails.some(e => e.valueType === 'Value')) {
    for(let INDEX = 0 ; INDEX < this.state.ConfigData.headerDetails.length ; INDEX++){
      if(this.state.ConfigData.headerDetails[INDEX].valueType === 'Value'){
        newVal.headers[this.state.ConfigData.headerDetails[INDEX].headerKey] = this.state.ConfigData.headerDetails[INDEX].headerValue ;
        }
      }
    }
    this.setState({ConfigData : newVal});
   if(this.state.ConfigData !== undefined && this.state.ConfigData.rtld1Mapping !== undefined && this.checkConsumerNested(this.state.LCSPayload, 'lead', 'lead_info','lead_consumer_info')) {
    console.log("Enter leads platform payload is nested object" );
    let propertydata = {}, consumerdata = {};
    if(this.checkConsumerNested(this.state.LCSPayload, 'lead', 'lead_info','lead_consumer_info')){
      let Consumer_Request = this.getConsumerNested(this.state.LCSPayload, 'lead', 'lead_info','lead_consumer_info');
      let leadKeys = Object.keys(Consumer_Request).map((v) => v)
      for (let rindex = 0; rindex < this.state.ConfigData.rtld1Mapping.length; rindex++) {
          let val = this.state.ConfigData.rtld1Mapping[rindex].leadField;
          // outerloop:
          for(let j = 0 ; j < leadKeys.length; j++){
              let keysl = leadKeys[j];
              if (this.state.ConfigData.rtld1Mapping[rindex].RTLD1Field.indexOf(keysl) > -1) {
                consumerdata[val] = Consumer_Request[keysl];
                    break;
                  }
              if (this.state.ConfigData.rtld1Mapping[rindex].RTLD1Field === undefined || this.state.ConfigData.rtld1Mapping[rindex].RTLD1Field === "" ) {
                consumerdata[val] = this.state.ConfigData.rtld1Mapping[rindex].defaultValue;
                    // break outerloop;
                  }
            }
      }
      }
       if(this.checkPropertyNested(this.state.LCSPayload, 'lead','lead_property_info')){
          console.log("Entered leads platform payload contains propertydata");
          let Property_Request = this.getPropertyNested(this.state.LCSPayload, 'lead', 'lead_property_info');
                  let leadKeys = Object.keys(Property_Request).map((v) => v)
                  for (let i = 0; i < this.state.ConfigData.rtld1Mapping.length; i++) {
                      let val = this.state.ConfigData.rtld1Mapping[i].leadField;
                      for(let j = 0 ; j < leadKeys.length; j++){
                          let keysl = leadKeys[j];
                          if (this.state.ConfigData.rtld1Mapping[i].RTLD1Field.indexOf(keysl) > -1) {
                            propertydata[val] = Property_Request[keysl];
                                break;
                              }
                        }
                  } 
          }
         
    payloadToSubmit = {...consumerdata, ...propertydata};
    if(this.state.ConfigData.payloadschematype === "JSON"){
      this.setState({ PayloadSubmit: JSON.stringify(payloadToSubmit), payloadToSubmitProgress: false });
    }
    else{
      const config = {indent: '    '};
      const newxml = toXML(payloadToSubmit, config);
      this.setState({ PayloadSubmit: newxml, payloadToSubmitProgress: false });
    }
  }   
   else if(this.state.ConfigData !== undefined && this.state.ConfigData.rtld1Mapping !== undefined && !this.checkConsumerNested(this.state.LCSPayload, 'lead', 'lead_info','lead_consumer_info')){
    console.log("Enter leads platform payload is direct object"); 
    if((!this.state.LCSPayload.hasOwnProperty("ClientPropertyID")) || (this.state.LCSPayload.hasOwnProperty("ClientPropertyID") && this.state.LCSPayload.ClientPropertyID === "" ) ){
      if(this.state.LCSPayload.hasOwnProperty("RDCURL")){
        let fulfillmentId = this.state.LCSPayload.RDCURL.split('/M');
          await this.getClientId(fulfillmentId[1])
      }
    }
 let leadKeys = Object.keys(this.state.LCSPayload).map((v) => v);
  for (let pindex = 0; pindex < this.state.ConfigData.payloadMapping.length; pindex++) {
    let val = this.state.ConfigData.payloadMapping[pindex].leadField;
    // outerloop:
    for (let j = 0; j < leadKeys.length; j++) {
      let keysl = leadKeys[j];
      if (this.state.ConfigData.payloadMapping[pindex].lcsField.indexOf(keysl) > -1) {
        payloadToSubmit[val] = (typeof this.state.ConfigData.payload[val] === 'number') ? parseInt(this.state.LCSPayload[keysl]) : this.state.LCSPayload[keysl];
        if(this.state.ConfigData.payloadMapping[pindex].lcsField === "FirstName LastName"){
          payloadToSubmit[val] = this.state.LCSPayload["FirstName"] +" "+ this.state.LCSPayload["LastName"]
          break;
        }
        break;
      }
      if(this.state.ConfigData.payloadMapping[pindex].lcsField === undefined || this.state.ConfigData.payloadMapping[pindex].lcsField === "" ){
        payloadToSubmit[val] = this.state.ConfigData.payloadMapping[pindex].defaultValue
        // break outerloop;
      }
    }
  }
    if(this.state.ConfigData.payloadschematype === "JSON"){
      this.setState({ PayloadSubmit: JSON.stringify(payloadToSubmit), payloadToSubmitProgress: false });
    }
    else{
      const config = {indent: '    '};
      let unflattenObject = (data) => {
        let result = {};
        for (let i in data) {
          let keys = i.split(".");
          keys.reduce((acc, value, index) => {
            return (
              acc[value] ||
              (acc[value] = isNaN(Number(keys[index + 1]))
                ? keys.length - 1 === index
                  ? data[i]
                  : {}
                : [])
            );
          }, result);
        }
        return result;
      };
      const xmldata = unflattenObject(payloadToSubmit);
      const newxml = toXML(xmldata, config);
      this.setState({ PayloadSubmit: newxml, payloadToSubmitProgress: false });
    }
  } 

   else {
    this.setState({ PayloadSubmit: this.state.PayloadSubmit, payloadToSubmitProgress: false });
  }
  }
  async callRequestUrl(obj){
    try {
      const getApiResponse = await axios.post(
        obj.oauthUrl,
        JSON.stringify(obj.oauthBody),
        obj.headers
      );
      return getApiResponse.data;
    }
    catch (error) {
      throw new Error(error);
    }
  }
  async getClientId(fulfillmentId){
    let query = {
      "query": "query homeSearch ( $query: HomeSearchCriteria! ) {  home_search ( query: $query ) {  results {  source {id listing_id }  } } }",
       "variables": { "query": {"property_id": `${fulfillmentId}`}}
      };
      const header = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      };
      const gethestiadata = await axios.post(`${CLIENTIDURL}`, query, header);
      try {
        var newvAL = this.state.LCSPayload;
        newvAL['ClientPropertyID'] = gethestiadata.data.data.home_search.results[0].source.listing_id;
        this.setState({LCSPayload: newvAL});
        return this.state.LCSPayload;
      } catch (e) {
        console.log(e);
      }
  }
  checkConsumerNested(obj) {
    let args = Array.prototype.slice.call(arguments, 1);
    for (var i = 0; i < args.length; i++) {
      if (!obj || !obj.hasOwnProperty(args[i])) {
        return false;
      }
      obj = obj[args[i]];
    }
    return true;
  }
  getConsumerNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  }
  checkPropertyNested(obj) {
    let args = Array.prototype.slice.call(arguments, 1);
    for (var i = 0; i < args.length; i++) {
      if (!obj || !obj.hasOwnProperty(args[i])) {
        return false;
      }
      obj = obj[args[i]];
    }
    return true;
  }
  getPropertyNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  }

  async submitHandler(){
    this.setState({ SpinnerFlag: true }); 
    let RequestUrl = this.state.ConfigData.apiurl;
    let Params = this.state.PayloadSubmit;
    let Headers = this.state.ConfigData.headerDetails.length > 1 ? {"headers": this.state.ConfigData.headers } : this.state.ConfigData.headers;
    console.log(RequestUrl);
    console.log(Params);
    console.log(Headers);

    try {
      const getApiResponse = await axios.post( RequestUrl, Params, Headers).then((res) => {
        if (res.ok) { return res.json(); } });
      this.setState({message: "SUCCESSFULLY SENT LEAD FROM LCM ", SpinnerFlag: false, open: true})
      return getApiResponse.data;
    }
    catch (error) {
      this.setState({ message: error.code +" "+ error.message, SpinnerFlag: false, open: true });
      return error;
    }
  }

  render() {
    return (
      <React.Fragment>
        <form>
          <div className="form-group col-xs-12 col-sm-12 col-lg-12 filter-bg">
          <div className="col-xs-12 col-sm-2 col-lg-2 filter-label">
                <FormControl style={{width: "100%"}}>
                <InputLabel style={{ fontSize: "13px" }}>Fullfillment Id</InputLabel>
                <Input size="md" style={{backgroundColor: "white", marginTop: "10px", borderRadius: "6px"}}
                  name="fullfillment_id"
                  input={<BootstrapInput />}
                  value={this.state.LeadConfig.FullfillmentId}
                  onChange={this.setFullfillmentId}
                >
                </Input>
              </FormControl>
            </div>
            <div className="col-xs-12 col-sm-2 col-lg-2 filter-label">
            { this.state.LeadConfig.FullfillmentId ?
              <FormControl style={{width: "100%"}}><InputLabel style={{ fontSize: "13px" }}>*Source</InputLabel>
                <Input size="md" style={{backgroundColor: "white", marginTop: "10px", borderRadius: "6px"}}
                  name="source" 
                  input={<BootstrapInput />}
                  value={this.state.LeadConfig.source}
                  disabled
                >
                </Input>{this.state.sourceProgress ? (<CircularProgress className="inputSpinner" /> ) : null}
              </FormControl> : 
              <FormControl style={{width: "100%"}}><InputLabel style={{ fontSize: "13px" }}>*Source</InputLabel>
                <NativeSelect
                  value={this.state.LeadConfig.source}
                  name="source"
                  input={<BootstrapInput />}
                  onChange={this.setCommon}
                >
                  <option aria-label="None" value="" />
                  {this.state.filterdSources !== undefined
                    ? this.state.filterdSources.map((id) => (
                        <option key={id} value={id}>
                          {id}
                        </option>
                      ))
                    : ""}
                </NativeSelect>
                {this.state.sourceProgress ? (<CircularProgress className="inputSpinner" /> ) : null} </FormControl>}
            </div>
            <div className="col-xs-12 col-sm-2 col-lg-2 filter-label">
              { this.state.LeadConfig.FullfillmentId ?
              <FormControl style={{width: "100%"}}><InputLabel style={{ fontSize: "13px" }}>*Datasource</InputLabel>
                <Input size="md" style={{backgroundColor: "white", marginTop: "10px", borderRadius: "6px"}}
                  name="datasource" 
                  input={<BootstrapInput />}
                  value={this.state.LeadConfig.datasource}
                  disabled
                >
                </Input>
              </FormControl> : 
              <FormControl style={{width: "100%"}}><InputLabel style={{ fontSize: "13px" }}>*Datasource</InputLabel>
                <NativeSelect
                  value={this.state.LeadConfig.datasource}
                  name="datasource"
                  input={<BootstrapInput />}
                  onChange={this.setCommon}
                >
                  <option aria-label="None" value="" />
                  {this.state.filterdDataSources !== undefined
                    ? this.state.filterdDataSources.map((id) => (
                        <option key={id} value={id}> {id} </option>
                      ))
                    : ""}
                </NativeSelect>
                {this.state.dataSourceProgress ? (<CircularProgress className="inputSpinner" /> ) : null}
              </FormControl> }
            </div>
            <div className="col-xs-12 col-sm-2 col-lg-2 filter-label">
            { this.state.LeadConfig.FullfillmentId ?
              <FormControl style={{width: "100%"}}><InputLabel style={{ fontSize: "13px" }}>Management</InputLabel>
                <Input size="md" style={{backgroundColor: "white", marginTop: "10px", borderRadius: "6px"}}
                  name="management" 
                  input={<BootstrapInput />}
                  value={this.state.LeadConfig.management}
                  disabled
                >
                </Input>
              </FormControl> : 
              <FormControl style={{width: "100%"}}> <InputLabel style={{ fontSize: "13px" }}>Management</InputLabel>
                <NativeSelect
                  value={this.state.LeadConfig.management}
                  name="management"
                  input={<BootstrapInput />}
                  onChange={this.setCommon}
                >
                  <option aria-label="None" value="" />
                  {this.state.filteredManagement !== undefined
                    ? this.state.filteredManagement.map((id) => (
                        <option key={id.management} value={id.management}>{id.management}</option>
                      ))
                    : ""}
                </NativeSelect>
                {this.state.managementProgress ? ( <CircularProgress className="inputSpinner" /> ) : null}
              </FormControl> }
            </div>
            <Button color="primary" variant="contained" onClick={this.clearFilters}>Reset</Button>
          
           
            {/* <div className="col-xs-12 col-sm-2 col-lg-2 filter-label">
              
              <FormControl style={{width: "100%"}}>
                <InputLabel style={{ fontSize: "13px" }}>*Service Type</InputLabel>
                <NativeSelect
                  value={this.state.servicetype}
                  name="servicetype"
                  input={<BootstrapInput />}
                  onChange={this.setServiceType}
                >
                  <option aria-label="None" value="" />
                  <option value="Partner">Partner Service</option>
                  <option value="RTLD1">RTLD Schema</option>
                </NativeSelect>
              </FormControl>
            </div> */}
           </div> 
        </form>
        {this.state.SpinnerFlag ? (
                <div className="form-spinner">
                  <CircularProgress className="CircularProgressStyle" />
                </div>
              ) : null}
        <div className="wrap">
        <div className="form-group col-xs-12 col-sm-12 col-lg-12">
        <div className="form-group col-xs-12 col-sm-2 col-lg-2">
          <label>Method:</label>
          <div>
            <input
              type="text"
              className="form-control"
              name="apimethod"
              readOnly
              defaultValue={this.state.ConfigData.apimethod}
            />
          </div>
        </div>
        <div className="form-group col-xs-12 col-sm-10 col-lg-10">
          <label>URL:</label>
          <div>
            <input
              type="text"
              className="form-control"
              name="apiUrl"
              defaultValue={this.state.ConfigData.apiurl}
              readOnly
            />
             {this.state.methodUrlProgress ? (
                    <CircularProgress className="inputSpinner" />
                  ) : null}
          </div>
        </div>
        </div>
        <div className="form-group col-xs-12 col-sm-12 col-lg-12">
        <div className="col-xs-5 col-sm-5 col-lg-5" style={{float: "left", width: "45%"}}>
          <label>Leads Platform Payload:</label>
          <div>
            <JSONInput
              width="100%"
              colors={{background : "#eee", string: "black",keys:"blue", number: "green"}}
              style={{container: {borderRadius: "5px"}}}
              placeholder={this.state.LCSPayload}
              name="payload"
              locale={locale}
              height="180px"
              onChange={this.setLeadsPlatformPayload}
            />
          </div>
        </div>
        <div className="col-xs-1 col-sm-1 col-lg-1 text-center" style={{float: "center",top: "25px"}} > 
        <button type="button" className="btn btn-sm" disabled={this.state.ConfigData.payload === undefined} onClick={() => this.getPayloadToSubmit()} variant="outlined">
            {" "}
            {">>"}{" "}
          </button>
        </div>
        <div className="col-xs-5 col-sm-5 col-lg-5" style={{float: "right", width: "45%"}}>
          <label>Payload to Submit:</label>
          <div>
            <textarea
              type="text"
              className="form-control"
              name="PayloadSubmit"
              required
              rows="9"
              defaultValue={this.state.PayloadSubmit}
              readOnly
            />
           </div>
          {this.state.payloadToSubmitProgress ? (
                    <CircularProgress className="inputSpinner" />
                  ) : null}
        </div>
        </div>
        
      <div className="col-xs-12 col-sm-12 col-lg-12 text-center"> 
      <button type="button" className="btn btn-sm fbtn" onClick={this.submitHandler}>
        Submit
      </button>
      </div>
          <Dialog open={this.state.fullfillmentCheck} maxWidth="xs" fullWidth>
            <Box position="absolute" top={0} right={0}> <IconButton onClick={this.clearFullfillment} >  <Close /> </IconButton> </Box>
            <DialogContent> <h5>No matched record found in database</h5>  </DialogContent>
            <DialogActions> <Button color="primary" variant="contained" onClick={this.clearFullfillment} > Ok </Button> </DialogActions>
          </Dialog>

          <Dialog open={this.state.open} maxWidth="xs" fullWidth>
            <Box position="absolute" top={0} right={0}> <IconButton onClick={this.clearSet} > <Close /> </IconButton></Box>
            <DialogContent> <h5>{this.state.message}</h5></DialogContent>
            <DialogActions> <Button color="primary" variant="contained" onClick={this.clearSet} >Ok</Button></DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}
