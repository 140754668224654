const dictionary = [
  {
    possibleKeys: ["listing_id", "listingkey", "listingid","listingId", "ekeyid", "id","ClientPropertyID"],
    LCSdata: "ClientPropertyID",
  },
  {
    possibleKeys: ["firstname", "lead_first_name", "first_name","FirstName"],
    LCSdata: "FirstName",
  },
  {
    possibleKeys: ["lead_last_name", "lastname", "last_name","LastName"],
    LCSdata: "LastName",
  },
  {
    possibleKeys: ["name", "prospect_name"],
    LCSdata: "FirstName LastName",
  },
  { possibleKeys: ["email", "lead_email", "Email"], LCSdata: "Email" },
  {
    possibleKeys: [
      "phone",
      "phodewdnenumber",
      "prospect_phone",
      "lead_phone",
      "PhoneNumber",
      "Phone"
    ],
    LCSdata: "Phone",
  },
  {
    possibleKeys: [
      "comments",
      "note",
      "notes",
      "message",
      "renters_message",
      "comment",
    ],
    LCSdata: "Comments",
  },
  {
    possibleKeys: [
      "moveindatetime",
      "moveindate",
      "movein_date",
      "move_in",
      "targetmoveindate",
    ],
    LCSdata: "movein_date",
  },
  { possibleKeys: ["url", "listingurl","RDCURL"], LCSdata: "RDCURL" },
  { possibleKeys: ["AuthenticationKey"], LCSdata: "AuthenticationKey" },
  { possibleKeys: ["ip", "ipaddress"], LCSdata: "ip" },
  { possibleKeys: ["browser","browser_info"], LCSdata: "browser_info" },
  { possibleKeys: ["clientlistingid","PropID"], LCSdata: "PropID" },
];
  export default dictionary;