import React from "react";

const ReadOnlyRow = ({ mData, handleEditClick, handleDeleteClick }) => {
  return (
    <tr>
      <td>{mData.leadField}</td>
      <td>{mData.RTLD1Field}</td>
      <td>{mData.defaultValue}</td>
      <td>
        <button type="button" onClick={(event) => handleEditClick(event, mData)}>Edit</button>
        <button type="button" onClick={() => handleDeleteClick(mData.mapping)}>Delete</button>
      </td>
    </tr>
  );
};

export default ReadOnlyRow;