const RTLD1dictionary = [
  {
    possibleKeys: ["firstname", "lead_first_name", "first_name", "FirstName"],
    RTLD1data: "first_name",
  },
  {
    possibleKeys: ["lead_last_name", "lastname", "last_name", "LastName"],
    RTLD1data: "last_name",
  },
  {
    possibleKeys: ["name", "prospect_name"],
    RTLD1data: "full_name",
  },
  { possibleKeys: ["email", "lead_email", "Email"], RTLD1data: "email" },
  {
    possibleKeys: [
      "phone",
      "phodewdnenumber",
      "prospect_phone",
      "lead_phone",
      "PhoneNumber",
      "Phone",
    ],
    RTLD1data: "phone",
  },
  {
    possibleKeys: [
      "comments",
      "note",
      "notes",
      "message",
      "renters_message",
      "comment",
    ],
    RTLD1data: "message",
  },
  {
    possibleKeys: [
      "moveindatetime",
      "moveindate",
      "movein_date",
      "move_in",
      "targetmoveindate",
    ],
    RTLD1data: "move_in_date",
  },
  {
    possibleKeys: [
      "listing_id",
      "listingkey",
      "listingid",
      "listingId",
      "ekeyid",
      "id",
      "ClientPropertyID",
    ],
    RTLD1data: "mls_id",
  },
  {
    possibleKeys: ["url", "listingurl", "RDCURL"],
    RTLD1data: "rdc_property_url",
  },
  { possibleKeys: ["AuthenticationKey"], RTLD1data: "AuthenticationKey" },
  { possibleKeys: ["ip", "ipaddress"], RTLD1data: "ip" },
  { possibleKeys: ["browser", "browser_info"], RTLD1data: "browser_info" },
  { possibleKeys: ["clientlistingid", "PropID"], RTLD1data: "rdc_property_id" },
];
export default RTLD1dictionary;
