
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/header';
import LeadConfiguration from './pages/LeadConfiguration/LeadConfiguration';
// import Mapper from './pages/Mapper/Mapper'; 
import MappingFilter from './pages/Mapper/MappingFilter'; 
import LeadDashboard from './pages/LeadConfiguration/LeadDashboard';
import Test from './pages/Test/Test';
const App = () => {
  return (
    <Router>
      <Header />
        <Routes>
          <Route exact path="/dashboard" element={ <LeadDashboard />} />
           <Route path="/mapper" element={<MappingFilter />} />
            <Route path="/leadConfig" element={<LeadConfiguration />} />
            <Route path="/test" element={<Test />} />
        </Routes>
    </Router>
  );
}

export default App;
