import React from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (<nav className="header-wrapper">
    <div className="logo">
       Lead Configuration Manager
    </div>
    <div className="nav-wrapper">
      <div className="navigation">
        <NavLink to="/dashboard" activeclassname="activeClass">
          Dashboard
        </NavLink>
        <NavLink to="/leadConfig" activeclassname="activeClass">
          LeadConfig
        </NavLink>
        <NavLink to="/mapper" activeclassname="activeClass">
          Mapper
        </NavLink>
        <NavLink to="/test" activeclassname="activeClass">
          Test
        </NavLink>
        </div>
        </div>
  </nav>)
}
export default Header;