import { TableBody, TableCell, TableHead, TableRow, Table, TableContainer, Paper, CircularProgress, Dialog, DialogContent, DialogActions, Button, Box, IconButton } from "@material-ui/core";
import React from "react";
import axios from "axios";
import { Close } from '@material-ui/icons';
import config from '../../config/config';
const NODEURL = config.getConfig().lcmService;
let searchFocusStyle = {};
class LeadDashboardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SpinnerFlag: false,
      LeadDashboardList: [],
      source: 'crd',
      open: false,
      deletedOpen: false,
      deleteRowVal: ""
    };
  }
  handleOpen(val) { 
    this.setState({ open: true, deleteRowVal : val.configId });
  }
  
  handleClose = () => this.setState({ snackAlertStatus: false });
  
  async getLeadDashboardList(val) {
    this.setState({ SpinnerFlag: true }); 
    console.log("getLeadDashboardList");
     let APIurl= `${NODEURL}/getAllConfiguration`;
    fetch(`${APIurl}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((response) => {
        console.log(response);
        this.setState({
          LeadDashboardList: response.LeadConfiguration,
          SpinnerFlag: false,
        });
       }).catch((error) => { 
        this.setState({LeadDashboardList : [], SpinnerFlag: false});
        console.log(error)})
  }
dashboardlist = (e) => {
  this.setState({ source: e.target.value });
  this.getLeadDashboardList(e.target.value);
}
  filterList = (event) => {
    var updatedList = this.state.LeadDashboardList;
    updatedList = updatedList.filter( function (item) {
      let searchedValue =  item.source.toString().toLowerCase()
      + item.datasource.toString().toLowerCase();
      return searchedValue.indexOf(event.target.value.toLowerCase()) !== -1;
    });
    this.setState({LeadDashboardList: updatedList});
    if(!event.target.value && !event.target.value.length){
      this.getLeadDashboardList();
    }
  }
  async deleteRow(val){
    this.setState({open : false, SpinnerFlag : true});
    let result = await axios.delete(`${NODEURL}/deleteConfiguration?configId=`+val).then((res) => {
     return res;
     }).catch((error) => {
        this.setState({deletedOpen: true, SpinnerFlag: false,  deletedOpen: true});
        console.log(error);
     });
     this.getLeadDashboardList();
  }
  UNSAFE_componentWillMount() {
    this.getLeadDashboardList();
  }
  render() {
    return (
        <div className="wrap">
           <div style={{ flex: "1 1 1%" }} >
           <div className="right-panel-action" style={{marginBottom : "10px"}}> 
                <div className="search-input-box">
                  <input
                    type="text"
                    className="form-control"
                    style={searchFocusStyle}
                    placeholder="Search..."
                    onChange={this.filterList}
                  />
                  <i className="glyphicon glyphicon-search" />
                </div>
              {/* <div className="search-input-box">
                <select
                      className="form-control"
                      required
                      name="source"
                      defaultValue={this.state.source}
                      onChange={this.dashboardlist}
                    >
                      <option value="crd">Community Rental</option>
                      <option value="mlr">Unit Rental</option>
                      <option value="new_homes">New Homes</option>
                      <option value="mls">MLS</option>
                    </select>
                    </div> */}
              {this.props.createFlag ? (
                <button type="button" className="btn btn-sm addbtn" onClick={() => this.props.handleLeadPages(false, "ADD")}>+ New Configuration</button>
              ) : null}
            </div>
           </div>
          <div>
          <TableContainer component={Paper}>
          <Table aria-label="simple table" >
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Datasource Name</TableCell>
                <TableCell>Management</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.SpinnerFlag ? (
                <div className="form-spinner">
                  <CircularProgress className="CircularProgressStyle" />
                </div>
              ) : null}
              {this.state.LeadDashboardList.length > 0 ? (
                this.state.LeadDashboardList.map((data,index) => {
                  return (
                    <TableRow key={data.configId}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{data.source}</TableCell>
                      <TableCell>{data.datasource}</TableCell>
                      <TableCell>
                        {data.management ? data.management : "-"}
                      </TableCell>
                      <TableCell align="center" style={{ width: "30px", padding: "10px", alignItems: "center" }}>
                        <a className="col-xs-2 col-lg-2" onClick={() => this.props.handleLeadPages(false, "EDIT", data)}><i className="glyphicon glyphicon-pencil" aria-hidden="true"/></a>
                        <a onClick={() => this.handleOpen(data)}> <i className="glyphicon glyphicon-trash"></i></a>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={5}>no record found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          </TableContainer>
          </div>
          <Dialog open={this.state.open} maxWidth="xs" fullWidth>
            <Box position="absolute" top={0} right={0}>
              <IconButton onClick={() => this.setState({open : false})} >
                <Close />
              </IconButton>
            </Box>
            <DialogContent>
              <h5>Are you sure you want to delete it!</h5>
            </DialogContent>
            <DialogActions>
              <Button color="primary" variant="contained" onClick={() => this.setState({open : false})} >Cancel</Button>
              <Button color="secondary" variant="contained" onClick={() => this.deleteRow(this.state.deleteRowVal)}>Confirm</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.deletedOpen} maxWidth="xs" fullWidth>
            <Box position="absolute" top={0} right={0}>
              <IconButton onClick={() => this.setState({deletedOpen : false})} >
                <Close />
              </IconButton>
            </Box>
            <DialogContent>
             <h5>Record deleted successfully</h5>
            </DialogContent>
            <DialogActions>
              <Button color="primary" variant="contained" onClick={() => this.setState({deletedOpen : false})} >Ok</Button>
            </DialogActions>
          </Dialog>
        </div>
    );
  }
}
export default LeadDashboardList;
