import defaultConfig from './defaultConfig';
import dev from './dev';
import local from './local';
import prod from './prod';
import qa from './qa';


const getEnv =  () => {

  const host = window.location.host;

  if (host.indexOf('localhost') !== -1 || host.indexOf('controllo.local') !== -1 || host.indexOf('0.0.0.0') !== -1) {
    return "local";
  } else if (host.indexOf('-dev') !== -1) {
    return "dev";
  } else if (host.indexOf('-qa') !== -1 || host.indexOf('qam') !== -1) {
    return "qa";
  }
  return "prod";
}
export default {

 getConfig: () =>  {
    const environment = {env: getEnv()};
    switch (environment.env) {
      case 'local':
        return {...environment, ...defaultConfig, ...local};

      case 'dev':
        return {...environment, ...defaultConfig, ...dev};

      case 'qa':
        return {...environment, ...defaultConfig, ...qa};

      case 'prod':
        return {...environment, ...defaultConfig, ...prod};
    }

  }
}
