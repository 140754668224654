import React, { useEffect, useState, Fragment } from "react";
import ReadOnlyRow from "./LCSMapperComponents/ReadOnlyRow";
import EditableRow from "./LCSMapperComponents/EditableRow";
import { Close } from '@material-ui/icons';
import { Dialog, DialogContent, DialogActions, Button, Box, IconButton } from "@material-ui/core";
import config from '../../config/config';
const NODEURL = config.getConfig().lcmService;
const Mapper = (props) => {
  const [LCSmappingData, setLCSMappingData] = useState([]);
  const [open, setSnackBar] = useState(false);
  const [message, setMessage] = useState();
  
  const fetchLCSMapping = () => {
    setLCSMappingData(props.ScannedData);
  };

  useEffect(() => {
    fetchLCSMapping();
  }, []);

  const [addFormData, setAddFormData] = useState({
    leadField: "",
    lcsField: "",
    mapping: "",
    defaultValue: "",
  });

  const [editFormData, setEditFormData] = useState({
    leadField: "",
    lcsField: "",
    mapping: "",
    defaultValue: "",
  });

  const [editContactId, setEditContactId] = useState(null);

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    const newContact = {
      mapping: LCSmappingData.length,
      leadField: addFormData.leadField,
      lcsField: addFormData.lcsField,
      defaultValue: addFormData.defaultValue,
    };
    const newMapping = [...LCSmappingData, newContact];
    setLCSMappingData(newMapping);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContact = {
      mapping: editContactId,
      leadField: editFormData.leadField,
      lcsField: editFormData.lcsField,
      defaultValue: editFormData.defaultValue,
    };
    const newMapping = [...LCSmappingData];
    const index = LCSmappingData.findIndex(
      (mData) => mData.mapping === editContactId
    );
    newMapping[index] = editedContact;
    setLCSMappingData(newMapping);
    setEditContactId(null);
  };

  const handleEditClick = (event, mData) => {
    event.preventDefault();
    setEditContactId(mData.mapping);
    const formValues = {
      leadField: mData.leadField,
      lcsField: mData.lcsField,
      defaultValue: mData.defaultValue
    };
    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditContactId(null);
  };

  const handleDeleteClick = (mappingIndex) => {
    const newMapping = [...LCSmappingData];
    const index = LCSmappingData.findIndex(
      (mData) => mData.mapping === mappingIndex
    );
    newMapping.splice(index, 1);
    setLCSMappingData(newMapping);
  };

  const handleClose = () => {setSnackBar(false); setLCSMappingData([])}
  const updateMapping = (id, newLCSMapping) => {
    fetch(`${NODEURL}/updateMapping?configId=` + id, {
      method: "PATCH",
      body: JSON.stringify(newLCSMapping),
      headers: {"Content-type": "application/json"},
    })
      .then((response) => response.json())
      .then((json) => { setSnackBar(true);
        setMessage("Record updated successfully");
      }).catch((error) => { setSnackBar(true);
        setMessage(error);
      });
  };
  const onSave = (id) => {
    const newMapping = LCSmappingData;
    updateMapping(id, newMapping);
  };
  return (
    <div className="wrap">
      <form onSubmit={handleEditFormSubmit}>
        <h5 style={{color: "blue"}}>{editContactId !== undefined ? `Mapping (${LCSmappingData.length} fields)` : "" }</h5>
        <table>
          <thead>
            <tr>
              <th>LeadField</th>
              <th>LcsField</th>
              <th>DefaultValue</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {LCSmappingData.map((mData) => (
              <Fragment>
                {editContactId === mData.mapping ? (
                  <EditableRow
                    editFormData={editFormData}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                  />
                ) : (
                  <ReadOnlyRow
                    mData={mData}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </form>

      <h5>Add Custom Field</h5>
      <form onSubmit={handleAddFormSubmit} style={{display: "flex", gap: "5px"}}>
        <input
          type="text"
          name="leadField"
          required="required"
          placeholder="Enter a leadField..."
          onChange={handleAddFormChange}
        />
        <input
          type="text"
          name="lcsField"
          placeholder="Enter a lcsField..."
          onChange={handleAddFormChange}
        />
         <input
          type="text"
          name="defaultValue"
          placeholder="Enter a defaultValue..."
          onChange={handleAddFormChange}
        />
        <button type="submit">Add</button>
      </form>
      <div className="col-xs-12 col-sm-12 col-lg-12 text-center">
        <button type="button" className="btn btn-sm fbtn" onClick={() => onSave(props.ConfigId)}>Save</button>
      </div>
      <Dialog open={open} maxWidth="xs" fullWidth>
          <Box position="absolute" top={0} right={0}>
              <IconButton onClick={handleClose} >
                <Close />
              </IconButton>
          </Box>
          <DialogContent>
             <h5>{message}</h5>
          </DialogContent>
          <DialogActions>
              <Button color="primary" variant="contained" onClick={handleClose} >Ok</Button>
          </DialogActions>
        </Dialog>
    </div>
  );
};

export default Mapper;
